import Button from '../components/base/Button'

const Error404 = () => {
    return (
        <div className="section pt-9">
            <div className="px-10">
                <div className="text-center container-sm error-page">
                    <h1>OOPS!</h1>
                    <h2>We looked everywhere and couldn’t find that page.</h2>
                    <p>
                        This might be because: You have typed the web address
                        incorrectly, or the page you were looking for may have
                        been moved, updated or deleted.
                    </p>
                    <div className="mt-5">
                        <Button
                            text="Back to home"
                            type="submit"
                            variant="primary"
                            size="lg"
                            icon="right-icon"
                            position="right"
                            link="/"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Error404
